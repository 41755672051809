.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #172554;
    padding: 50px 70px;
    margin-top: 50px;
}

.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo-container img {
    width: 100px;
    height: 100px;
}

.logo-container h1 {
    font-size: 32px;
    font-weight: 600;
    margin-top: 10px;
}

.footer-detail {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 30px;
}

.footer-heading {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
}

.footer-content {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 200;
    color: white;
}

.footer-content a {
    text-decoration: none;
    color: white;
    margin: 10px 0px;
    outline: none; /* Remove outline */
    -webkit-tap-highlight-color: transparent; /* Remove mobile tap highlight */
}

.footer-content a:hover {
    color: #172554;
}

.footer-content a:focus {
    outline: none; /* Remove outline */
}

.footer-content a:active {
    outline: none; /* Remove outline */
}

.footer-content a:visited {
    color: white;
}

.icon-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.icon-content img {
    width: 30px;
    height: 30px;
    margin: 0 10px;
}

.footer-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    color: white;
    font-weight: 200;
}

@media screen and (max-width: 768px) {
    .footer {
        padding: 50px 50px;
        
    }

    .logo-container {
        align-items: flex-start;
        margin-left: 0px;
        margin-bottom: 20px;
    }

    .footer-container {
        flex-direction: column;
    }

    .footer-section {
        margin: 20px 0px;
    }

    .footer-detail {
        flex-direction: column;
    }

    .icon-content {
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 30px;
    }

    .icon-content img {
        width: 30px;
        height: 30px;
        margin-right: 20px;
    }

    .footer-social {
        margin-top: 20px;
    }
}
