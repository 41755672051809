.contact {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 8%;
}

.contact h2 {
    color: #172554;
    text-align: center;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
}

.contact p {
    padding-top: 25px;
    color: #172554;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contact .contact-container {
    padding-top: 111px;
    margin: auto;
    max-width: 612px;
    text-align: left;
    color: #172554;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 40px;
}

.contact .contact-container input,
.contact .contact-container textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid #172554;
    outline: none;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
    padding: 12px 0;
    padding-left: 35px;
}

.contact .contact-container input::placeholder,
.contact .contact-container textarea::placeholder {
    color: #172554;
    opacity: 0.5;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.contact .contact-container .field {
    padding-bottom: 50px;
    border-radius: 0px;
}

.contact .contact-container #message {
    height: 110px;
    resize: none;
    border-bottom: 1px solid #172554;
}

.contact button {
    width: 241px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #172554;
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.contact button:hover {
    color: #172554;
    background-color: #fafafa;
    border: 1px solid #172554;
}


@media (max-width: 450px) {
    .contact {
        text-align: center !important;
        justify-content: center !important;
        align-items: center !important;
        margin: 0 !important;
        padding: 5% !important;
    }

    .contact h2 {
        color: #172554 !important;
        text-align: center !important;
        font-size: 28px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
    }

    .contact p {
        padding-top: 25px !important;
        color: #172554 !important;
        text-align: center !important;
        font-size: 12px !important;
        font-style: normal !important;
        line-height: normal !important;
    }

    .contact .contact-container {
        padding-top: 50px !important;
        margin: auto !important;
        width: 100% !important;
        text-align: left !important;
        color: #172554 !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
        padding-bottom: 40px !important;
    }

    .contact .contact-container input,
    .contact .contact-container textarea {
        font-size: 12px !important;
        padding: 12px 0 !important;
        padding-left: 20px !important;
    }

    .contact .contact-container input::placeholder,
.contact .contact-container textarea::placeholder {
    font-size: 12px !important;
}

.contact .contact-container .resumeField {
    font-size: 12px !important;

}

.contact .contact-container .upload-container {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 35px;
    margin-top: 12px;
}

.contact .contact-container .upload-box {
    width: 194px;
    height: 35px;
    flex-shrink: 0;
    border: 1px solid #172554;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding-left: 0px;
}


.contact .contact-container .upload-button {
    width: 90px;
    height: 35px;
    flex-shrink: 0;
    border: none;
    background-color: #172554;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 0px;
    transition: background-color 0.3s, color 0.3s;
}

.contact button {
    width: 112px !important;
    height: 35px !important;
    flex-shrink: 0;
    border-radius: 10px !important;
    background: #172554;
    color: #FFF;
    text-align: center;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0px;
    transition: background-color 0.3s, color 0.3s;
}


}