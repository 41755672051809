.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 100%;
    margin-top: 50px;
    padding: 0 8%;
    position: relative;
    box-sizing: border-box;
}

.logo {
    position: absolute;
    left: 8%;
    cursor: pointer;
}

.logo img {
    width: 64px;
    height: auto;
}

.nav-links {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    /* Remove default margin */
    padding: 0;
    /* Remove default padding */
}

.nav-links li {
    padding: 0 20px;
}

.home,
.about,
.career {
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    color: #172554;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 20px;
}

.navbar-button {
    position: absolute;
    right: 8%;
    display: block;
   
}

.navbar-image {
    display: none;
}

.navbar-button button {
    background-color: #172554;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 15px;
    width: 150px;
    height: 53px;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    border: none;

}

.navbar-button button:hover {
    color: #172554;
    background-color: #fafafa;
    border: 1px solid #172554;
    font-weight: 500;
}


@media (max-width: 450px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        width: 100%;
        box-sizing: border-box;
        padding: 0 5% 0 5%;
        margin-top: 30px;

    }

    .logo {
        position: relative;
        left: 0;
        cursor: pointer;
    }

    .logo img {
        width: 27px;
        height: 34px;
        flex-shrink: 0;
    }

    .nav-links {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .nav-links li {
        padding: 0;
    }

    .home,
    .about,
    .career {
        text-decoration: none;
        cursor: pointer;
        color: #172554;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 15px;
        /* background-color: aqua; */
    }

    .button {
        cursor: pointer;
    }

    .navbar-button {
        display: none;
    }

    .navbar-image {
        display: block;
    }
}