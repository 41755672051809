.button-group {
    width: 600px;
    height: 53px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    margin-top: 120px;
    border-radius: 15px;
    border: 1px solid #000;
}

.button-group button {
    background-color: transparent;
    width: 33.33%; 
    height: 100%; 
    border: none;
    cursor: pointer;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}


.button-group button p {
    margin: 0;
    padding: 0;
    color: #172554;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button-group button.active,
.button-group button:hover {
    background-color: #172554;
}

.button-group button.active p,
.button-group button:hover p {
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.page-link {
    color: #172554;
    text-decoration: none;
    cursor: pointer;
}

.page-item.disabled .page-link,
.page-item.active .page-link {
    background-color: #172554;
    color: #172554;
    border-radius: 5px;
    pointer-events: none;
}

.page-item {
    display: inline-block;
    margin: 0 5px;
}

.page-link:hover {
    background-color: #172554;
    color: #172554;
}


@media (max-width: 1030px) {
    .button-group {
        border-radius: 10px;
        margin-top: 35px; 
        width: 98%;
        height: 35px;
        flex-shrink: 0;
    }

    .button-group button {
        width: 100%; 
        border-radius: 8px; 
        margin-right: 0; 
    }

    .button-group button p {
        font-size: 10px; 
    }

    .button-group button.active p,
    .button-group button:hover p {
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }

    .page-item {
        margin: 0 3px; 
    }
}
