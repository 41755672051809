.landing-page {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.hero-section {
    display: flex;
    justify-content: space-between;
    margin: 20px 8%;
}

.hero-section-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: -70px; */
    margin-right: 50px;
}

.hero-section-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.hero-section-head {
    color: #172554;
    font-size: 54px;
    font-weight: 700;
    text-align: left;
}

.hero-section-para {
    color: #172554;
    font-size: 15px;
    text-align: left;
    margin-top: 20px;
}

.hero-section-para-mobile {
    display: none;
}

.hero-button button {
    background-color: #172554;
    color: #fff;
    width: 180px;
    height: 53px;
    border-radius: 15px;
    margin-top: 54px;
    transition: background-color 0.3s, color 0.3s;
    font-weight: 500;
    cursor: pointer;
    border: none;
}

.hero-button button:hover {
    color: #172554;
    background-color: #fafafa;
    border: 1px solid #172554;
    font-weight: 500;
}

.hero-section-img img {
    width: 450px;
    height: auto;
    display: block;
    margin-top: 50px;

}

.service-section-head,
.work-showcase-head,
.explore-section-head {
    color: #172554;
    font-size: 54px;
    font-weight: 700;
    text-align: center;
}

.service-section {
    justify-content: center;
    align-items: center;
    margin-top: 120px;
}

.service-box-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 70px 8%;
}

.service-box {
    width: 380px;
    height: 320px;
    border-radius: 20px;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid #172554;
    transition: 0.3s;
}

.service-box-icon {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    background-color: #172554;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 15px;
}

.service-box-icon img {
    width: 50px;
    height: 50px;
}

.service-box-head {
    color: #172554;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.service-box-para {
    color: #172554;
    font-size: 15px;
    text-align: center;
    margin-top: 25px;
}

.work-showcase {
    justify-content: center;
    align-items: center;
    margin: 180px 8%;
}

.work-showcase-para {
    color: #172554;
    font-size: 15px;
    text-align: center;
    margin-top: 40px;

}

.slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.slider-button {
    position: absolute;
    border-radius: 50%;
    top: 55%;
    background-color: #172554;
    color: white;
    border: none;
    padding: 3px 16px;
    cursor: pointer;
    z-index: 1;
    font-size: 30px;
}

.slider-button-left {
    left: 8px;
}

.slider-button-right {
    right: 8px;
}

.building-images {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    /* Adjusted width to fit three images */
    margin: 0 auto;
    /* Center the container */
    margin-top: 70px;
}

.building-images img {
    width: 30%;
    /* Each image takes up 1/3 of the container width */
    height: auto;
    border-radius: 20px;
    margin: 0 15px;
    transition: transform 0.5s ease-in-out;
    /* Add transition for smooth movement */
}

.explore-box-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 70px 8%;
}

.explore-box {
    width: 380px;
    height: 250px;
    border-radius: 20px;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Adjusted for button */
    align-items: center;
    padding: 20px;
    border: 1px solid #172554;
    transition: 0.3s;
}

.explore-box-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.explore-count {
    width: 100px;
    /* Fixed size */
    height: 100px;
    /* Fixed size */
    border-radius: 20px;
    background-color: #172554;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 18px;
    /* Adjusted for spacing */
    margin-left: 10px;
    font-size: 28px;
    font-weight: 700;
    flex-shrink: 0;
    /* Prevent shrinking */
}

.explore-content {
    text-align: left;
}

.explore-head {
    color: #172554;
    font-size: 18px;
    font-weight: 600;
}

.explore-para {
    color: #172554;
    font-size: 13px;
    margin-top: 10px;
}

.explore-button {
    background-color: white;
    color: #172554;
    border: 1px solid #172554;
    border-radius: 15px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.3s, color 0.3s;
    width: 100%;
    height: 53px;
    margin-bottom: 10px;
}

.link {
   width: 94%;
}

.explore-button:hover {
    background-color: #172554;
    color: white;
}


@media (max-width: 450px) {
    .landing-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0% 5% 5% 5%;
    }

    .hero-section {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 20px 0%;

    }
    .hero-section-left {
        margin-right: 0;
    }

    .hero-section-head {
        color: #172554;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 0;
        margin: 0;
    }

    .hero-section-para {
        display: none;
    }

    .hero-section-para-mobile {
        padding: 0;
        width: 100%;
        display: block;
        color: #172554;
        text-align: center;
        margin-top: 20px;
        color: #172554;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .hero-button {
        padding: 0;
        margin: 0;
        margin-top: 25px;
        /* Adjusted margin for spacing */

    }

    .hero-button button {
        margin: 0;
        width: 100px;
        height: 35px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #172554;
        color: #FFF;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .hero-section-img img {
        width: 100%;
        /* Make the image responsive */
        height: auto;
        /* Maintain aspect ratio */
        /* margin-top: 20px; */
        /* Added margin for spacing */
    }


    .service-section-head,
    .work-showcase-head,
    .explore-section-head {
        color: #172554;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
}

.service-section {
    margin: 60px 0 0 0 ;
    width: 100%;
}

.service-box-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    
}

.service-box {
    width: 100%;
    height: 265px;
    border-radius: 20px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:0 20px 0 20px;
    border: 1px solid #172554;
    transition: 0.3s;
}

.service-box-head {
    color: #172554;
text-align: center;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.service-box-para {
    margin-top: 25px;
    color: #172554;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.service-box-icon {
    margin-top: 0;
    width: 86px;
    height: 88px;
    flex-shrink: 0;

}

.work-showcase {
    justify-content: center;
    align-items: center;
    margin: 60px 0 0 0;
}

.work-showcase-para {
    margin-top: 20px;
    color: #172554;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.slider-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
}

.building-images {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    /* Adjusted width to fit three images */
    margin: 0 auto;
    /* Center the container */
    margin: 0;
}

.building-images img {
    width: 100%;
    padding: 5%;
    /* Each image takes up 1/3 of the container width */
    height: auto;
    border-radius: 20px;
    margin: 0 15px;
    transition: transform 0.5s ease-in-out;
    /* Add transition for smooth movement */
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #172554;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 18px;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-button-left {
    left: -1.25px;
}

.slider-button-right {
    right: -1.25px;
}

.explore-section {
    margin-top: 60px;
    width: 100%;
    
}


.explore-box-section {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
}

.explore-box-detail {
    width: 100%;
}

.explore-count {
    width: 88px;
height: 87px;
flex-shrink: 0;
font-size: 22px;
}
.explore-box {
    width: 100%;
    margin-top: 25px;
    height: 220px;
    border-radius: 20px;
    /* margin: 0 25px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Adjusted for button */
    align-items: center;
    /* padding: 20px; */
    border: 1px solid #172554;
    transition: 0.3s;
}

.explore-head {
    color: #172554;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.explore-para {
    color: #172554;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.explore-button {
    /* width: 230px; */
height: 40px;
width: 100%;
/* flex-shrink: 0; */
}

    
}

@media (max-width: 330px) {

    .service-box-head {
        color: #172554;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
    }
    
    .service-box-para {
        color: #172554;
        font-size: 10px;
        text-align: center;
        margin-top: 25px;
    }

    .service-box-icon {
        margin-top: 0;
        width: 82px;
        height: 84px;
        flex-shrink: 0;
    
    }

    .explore-count {
        width: 84px;
    height: 82x;
    flex-shrink: 0;
    font-size: 20px;
    }


    .explore-head {
        color: #172554;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .explore-para {
        color: #172554;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
